<template>
  <div>
     <div class="text-center p-t-10">
      <div
        @click="handleChangeTag(item)"
        v-for="(item,index) in tagArray"
        :key="index"
        :class="approvalType=== item.typeKey?'workplatform-radio-button-active':'workplatform-radio-button'"
        class="p-l-20 p-r-20"
      >{{item.name}}</div>
    </div>

    <component :is="approvalType + 'Component'" />

  </div>
</template>

<script>
export default {
  components: {
    specialComponent: () => import('@/components/approvalCreate/components/Special'),
    fineComponent: () => import('@/components/approvalCreate/components/Fine'),
    noticeComponent: () => import('@/components/approvalCreate/components/Notice')
  },
  data () {
    return {
      tagArray: [
        { name: '通用审批', typeKey: 'special' },
        { name: '罚单', typeKey: 'fine' },
        { name: '通知', typeKey: 'notice' }
      ]
    }
  },
  methods: {
    handleChangeTag (tag) {
      this.$store.commit('set_approvalCreate_approvalType', tag.typeKey)
    }
  },
  computed: {
    approvalType () {
      return this.$store.state.approvalCreate ? this.$store.state.approvalCreate.approvalType : 'special'
    }
  }
}
</script>
